import React, { useState } from 'react';
import HttpStatus from 'http-status-codes';
import LoginForm from '../../components/auth/LoginForm';
import { login } from '../../api';

export default function Login({ setUser, frontendVersion, backendVersion, country }) {
	const [error, setError] = useState(null);
	const [enterToken, setEnterToken] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	return <LoginForm error={error} enterToken={enterToken} submitting={submitting} onSubmit={(email, password, token) => {
		setSubmitting(true);
		setError(null);
		login(email, password, token).then(res => {
			if(res.status === HttpStatus.PARTIAL_CONTENT) {
				setEnterToken(true);
			} else {
				setUser(res.data);
			}
			setSubmitting(false);
		}).catch(err => {
			setError(err);
			setSubmitting(false);
		});
	}} frontendVersion={frontendVersion} backendVersion={backendVersion} country={country} />;
}
