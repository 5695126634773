import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CrumbsProvider } from './components/navigation/Breadcrumbs';
import App from './containers/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/index.css';
import { BackgroundProvider } from './hooks/background';

const root = createRoot(document.getElementById('root'));
root.render(<BrowserRouter>
	<React.StrictMode>
		<CrumbsProvider>
			<BackgroundProvider>
				<App />
			</BackgroundProvider>
		</CrumbsProvider>
	</React.StrictMode>
</BrowserRouter>);
