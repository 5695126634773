import Alert from 'react-bootstrap/Alert';

export default function Error({ error, ...props }) {
	if(!error) {
		return null;
	}
	let { message } = error;
	if(error.data?.invalid?.length) {
		message = <>
			{message}
			<ul>
				{error.data.invalid.map((field, i) => <li key={i}>{field}</li>)}
			</ul>
		</>;
	}
	return <Alert variant="danger" {...props}>{message}</Alert>;
}
