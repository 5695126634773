import { createContext, useContext, useEffect, useMemo, useState } from 'react';

const Context = createContext();

function useBodyColor(bodyColor = '') {
	useEffect(() => {
		document.body.style.backgroundColor = bodyColor;
	}, [bodyColor]);
}

export function BackgroundProvider({ children }) {
	const [state, setState] = useState({});
	useBodyColor(state.body);
	const setters = useMemo(() => {
		const set = (k, v) => {
			setState(prev => {
				if(typeof v === 'function') {
					v = v(prev[k]);
				}
				if(prev[k] === v) {
					return prev;
				}
				return { ...prev, [k]: v };
			});
		};
		return {
			setBody: v => set('body', v),
			setNav: v => set('nav', v)
		};
	}, []);
	Object.assign(state, setters);
	return <Context.Provider value={state} children={children} />;
}

export function useBackground() {
	return useContext(Context);
}
