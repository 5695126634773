const FORMATTERS = {};
const DELAY = 500;
const DIGITS = 4;
export const NUMBER_FORMATTER = (() => {
	try {
		return new Intl.NumberFormat(navigator.language);
	} catch(ignored) {
		return {
			format(number) {
				return `${number}`;
			}
		};
	}
})();

export function getCurrencyFormatter(currency, maximumFractionDigits) {
	const key = `${currency} ${maximumFractionDigits}`;
	if(!FORMATTERS[key]) {
		try {
			FORMATTERS[key] = new Intl.NumberFormat(navigator.language, { style: 'currency', currency, maximumFractionDigits });
		} catch(ignored) {
			FORMATTERS[key] = {
				format(number) {
					return `${currency} ${number}`;
				}
			};
		}
	}
	return FORMATTERS[key];
}

export function wait(cancel, timeout) {
	return new Promise(resolve => {
		cancel.timeout = setTimeout(resolve, timeout);
	});
}

export const EUR = getCurrencyFormatter('EUR');
export const EUR4 = getCurrencyFormatter('EUR', DIGITS);

export function delaySearch(f, setError) {
	let timeout = 0;
	let promise;
	let onDelayed;
	let argument;
	return a => {
		argument = a;
		clearTimeout(timeout);
		if(promise) {
			timeout = setTimeout(onDelayed, DELAY);
		} else {
			promise = new Promise(resolve => {
				onDelayed = resolve;
				timeout = setTimeout(onDelayed, DELAY);
			}).then(() => {
				promise = null;
				return f(argument);
			}).catch(err => {
				setError(err);
				return [];
			});
		}
		return promise;
	};
}

export async function getOption(value, f) {
	if(!value) {
		return value;
	}
	try {
		return await f(value);
	} catch(ignored) {
		return { value, label: value };
	}
}
