import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { BASE, startQuarterlyExportTask } from '../../api';
import { useTask } from '../../hooks/task';
import Error from '../Error';

const CURRENT_YEAR = new Date().getFullYear();

export default function QuarterlyExport() {
	const [quarter, setQuarter] = useState('Q1');
	const [year, setYear] = useState(CURRENT_YEAR);
	const [id, setId] = useState(null);
	const [error, setError] = useState(null);
	const { task, fetching } = useTask(id, setError);
	return <div className="rounded-1 border border-2 bg-white shadow-sm">
		<div className="p-3">
			<div className="d-flex mb-2">
				<select value={quarter} onChange={e => setQuarter(e.target.value)} className="form-control me-2">
					<option value="Q1">Q1</option>
					<option value="Q2">Q2</option>
					<option value="Q3">Q3</option>
					<option value="Q4">Q4</option>
				</select>
				<input type="number" className="form-control" min="2022" max={CURRENT_YEAR} value={year} onChange={e => setYear(e.target.value)} />
			</div>
			<Error error={error} />
			<Button variant="primary" className="w-100" disabled={fetching} onClick={e => {
				e.preventDefault();
				setError(null);
				setId(null);
				startQuarterlyExportTask(year, quarter).then(res => setId(res.task)).catch(setError);
			}}>Prepare Export</Button>
			{task && id && !fetching && <a target="_blank" className="btn btn-primary d-block mt-1" href={`${BASE}/transactions/export/${id}`}>Download Export</a>}
		</div>
	</div>;
}
