import axios from 'axios';
import queryString from 'query-string';
import HTTP_STATUS_CODES from 'http-status-codes';

export const BASE = '/api';

const client = axios.create({
	baseURL: BASE,
	paramsSerializer: p => queryString.stringify(p)
});

client.interceptors.response.use(res => res, err => {
	if(err.config.url !== '/auth/me' && err.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
		window.location.replace('/');
	}
	if(err.response?.data?.message) {
		const error = new Error(err.response.data.message);
		error.data = err.response.data;
		error.status = err.response.status;
		err = error;
	}
	return Promise.reject(err);
});

function getData(res) {
	return res.data;
}

export function getVersion() {
	return client.get('/version').then(getData);
}

export function checkLogin() {
	return client.get('/auth/me').then(getData);
}

export function updateMe(updates) {
	return client.put('/auth/me', updates);
}

export function login(email, password, token) {
	return client.post('/auth/login', { email, password, token });
}

export function logout() {
	return client.post('/auth/logout');
}

export function getUsers(page) {
	return client.get('/users', { params: { page } }).then(getData);
}

export function createUser(user) {
	return client.post('/users', user).then(getData);
}

export function getUser(id) {
	return client.get(`/users/${id}`).then(getData);
}

export function editUser(id, user) {
	return client.put(`/users/${id}`, user);
}

export function getRoles() {
	return client.get('/roles').then(getData);
}

export function generate2FA() {
	return client.post('/auth/2fa/generate').then(getData);
}

export function set2FA(secret, confirm) {
	return client.post('/auth/2fa/confirm', { secret, confirm });
}

function getExactRedirect() {
	return `${window.location.origin}/oauth/exact/redirect`;
}

export function getExactOAuthUrl() {
	const redirect = getExactRedirect();
	return client.get('/exact/oauth/url', { params: { redirect } }).then(res => res.data.url);
}

export function exchangeExactOAuthCode(code) {
	const redirect = getExactRedirect();
	return client.post('/exact/oauth/code', { code, redirect });
}

export function getPinDevice(id) {
	return client.get(`/devices/${id}`).then(getData);
}

export function updatePinDevice(id, device) {
	return client.put(`/devices/${id}`, device);
}

export function createPinDevice(device) {
	return client.post('/devices', device).then(getData);
}

export function getPinDevices(offset, limit, active, search, deviceType, partner) {
	return client.get('/devices', { params: { offset, limit, active, search, deviceType: deviceType !== '' ? deviceType : undefined, partner: partner !== '' ? partner : undefined } }).then(getData);
}

export function deletePinDevice(id) {
	return client.delete(`/devices/${id}`);
}

export function getDeviceType(id) {
	return client.get(`/deviceTypes/${id}`).then(getData);
}

export function getDeviceTypes(active) {
	return client.get('/deviceTypes', { params: { active } }).then(getData);
}

export function createDeviceType(type) {
	return client.post('/deviceTypes', type).then(getData);
}

export function deleteDeviceType(id) {
	return client.delete(`/deviceTypes/${id}`);
}

export function updateDeviceType(id, type) {
	return client.put(`/deviceTypes/${id}`, type);
}

export function getTransactions(start, end, page) {
	return client.get('/abn/transactions', { params: { start, end, page } }).then(getData);
}

export function getSavedTransactions(offset, limit, params) {
	return client.get('/transactions', { params: { offset, limit, ...params } }).then(getData);
}

export function saveTransactions(start, end) {
	return client.post('/transactions/fetch', { start, end }).then(getData);
}

export function getMultiSafepayTransactions(offset, limit, params) {
	return client.get('/transactions/msp', { params: { offset, limit, ...params } }).then(getData);
}

export function saveMultiSafepayTransactions(start, end) {
	return client.post('/transactions/fetchmsp', { start, end }).then(getData);
}

export function updateTransactions(state, ids) {
	return client.post('/transactions/update', { state, ids });
}

export function getTransactionStats() {
	return client.get('/transactions/stats').then(getData);
}

export function getTransactionSumData() {
	return client.get('/transactions/sum').then(getData);
}

export function getTransactionMonthlyData() {
	return client.get('/transactions/monthly').then(getData);
}

export function getTop10Devices() {
	return client.get('/transactions/topdevices').then(getData);
}

export function getTop10Customers(params) {
	return client.get('/transactions/topcustomers', { params }).then(getData);
}

export function startTransactionUpload() {
	return client.post('/transactions/startupload').then(getData);
}

export function startTransactionApproval() {
	return client.post('/transactions/startapprove').then(getData);
}

export function startTransactionDeletion(minDate, maxDate) {
	return client.post('/transactions/startdelete', { minDate, maxDate }).then(getData);
}

export function startTransactionReparse() {
	return client.post('/transactions/startreparse').then(getData);
}

export function startTransactionExportFilter(file, partner) {
	const formData = new FormData();
	formData.append('partner', JSON.stringify(partner));
	formData.append('file', file, file.name);
	return client.post('/transactions/startfilter', formData).then(getData);
}

export function startTransactionArchiving(minDate, maxDate) {
	return client.post('/transactions/startarchive', { minDate, maxDate }).then(getData);
}

export function startTransactionQuantityImport(file) {
	const formData = new FormData();
	formData.append('file', file, file.name);
	return client.post('/transactions/startquantityimport', formData).then(getData);
}

export function updateBulkTask(id, state, type) {
	return client.put(`/${type}/${id}`, { state });
}

export function getDeviceLinks(offset, limit, params) {
	return client.get('/links', { params: { offset, limit, ...params } }).then(getData);
}

export function linkDevice(link) {
	return client.post('/links', link).then(getData);
}

export function getDeviceLink(id) {
	return client.get(`/links/${id}`).then(getData);
}

export function updateDeviceLink(id, link) {
	return client.put(`/links/${id}`, link).then(getData);
}

export function unlinkDevice(id) {
	return client.delete(`/links/${id}`).then(getData);
}

export function getCustomer(id) {
	return client.get(`/links/customers/${id}`).then(getData);
}

const MINIMUM_QUERY_LENGTH = 3;
export function findCustomers(query) {
	if(query.length < MINIMUM_QUERY_LENGTH) {
		return [];
	}
	return client.post('/links/customers', { query }).then(getData);
}

export function getDebitor(id) {
	return client.get(`/links/debitors/${id}`).then(getData);
}

export function findDebitors(query) {
	if(query.length < MINIMUM_QUERY_LENGTH) {
		return [];
	}
	return client.post('/links/debitors', { query }).then(getData);
}

export function getItem(id) {
	return client.get(`/links/items/${id}`).then(getData);
}

export function findItems(query) {
	return client.post('/links/items', { query }).then(getData);
}

export function getCreditors(page, query) {
	return client.get('/exact/creditors', { params: { page, query } }).then(getData);
}

export function getVATCode(code) {
	return client.get(`/exact/vat/${encodeURIComponent(code)}`).then(getData);
}

export function getRecentTasks(params) {
	return client.get('/tasks', { params }).then(getData);
}

export function getTask(id) {
	return client.get(`/tasks/${id}`);
}

export function getPurchaseEntries(offset, limit, params) {
	return client.get('/purchaseentries', { params: { ...params, offset, limit } }).then(getData);
}

export function startReportGeneration(params) {
	return client.post('/purchaseentries/startreport', params).then(getData);
}

export function getReports(offset, limit, params) {
	return client.get('/reports', { params: { ...params, offset, limit } }).then(getData);
}

export function startMailingReports(ids) {
	return client.post('/reports/startmail', { ids }).then(getData);
}

export function startDeletingReports() {
	return client.post('/reports/startdelete').then(getData);
}

export function deleteReports(ids) {
	return client.post('/reports/delete', { ids });
}

export function getReportSize() {
	return client.get('/reports/size').then(getData);
}

export function createInvoiceCards(type, data) {
	return client.post(`/invoices/create/${type}`, data).then(getData);
}

export function getInvoiceCards(state, offset, limit, params) {
	return client.get(`/invoices/${state}`, { params: { ...params, offset, limit } }).then(getData);
}

export function getInvoiceCard(id) {
	return client.get(`/invoices/card/${id}`).then(getData);
}

export function updateInvoiceCards(state, ids) {
	return client.post('/invoices/update', { state, ids });
}

export function startInvoiceCardApproval() {
	return client.post('/invoices/startapprove').then(getData);
}

export function startInvoiceCardUpload(ids) {
	return client.post('/invoices/startupload', { ids }).then(getData);
}

export function deleteInvoiceCards(ids) {
	return client.post('/invoices/delete', { ids });
}

export function getConfiguration() {
	return client.get('/configuration').then(getData);
}

export function updateConfiguration(data) {
	return client.put('/configuration', data);
}

export function getSetting(id) {
	return client.get(`/configuration/${encodeURIComponent(id)}`).then(getData);
}

export function getDeviceAvailability(start, end, partner) {
	return client.post('/availability', { start, end, partner }).then(getData);
}

export function getCalls(interval) {
	return client.get(`/calls/${interval}`).then(getData);
}

export function startQuarterlyExportTask(year, quarter) {
	return client.get(`/transactions/export/${year}/${quarter}`).then(getData);
}

export function getPartnerCode(id) {
	return client.get(`/partnerCodes/${id}`).then(getData);
}

export function getPartnerCodes(active) {
	return client.get('/partnerCodes', { params: { active } }).then(getData);
}

export function createPartnerCode(type) {
	return client.post('/partnerCodes', type).then(getData);
}

export function deletePartnerCode(id) {
	return client.delete(`/partnerCodes/${id}`);
}

export function updatePartnerCode(id, type) {
	return client.put(`/partnerCodes/${id}`, type);
}

export function getPartnerTransactions(min, max, partners) {
	return client.post('/transactions/byPartner', { min, max, partners }).then(getData);
}

export function getCustomerTransactionStats(start, end, customers) {
	return client.post('/transactions/stats/customer', { start, end, customers }).then(getData);
}

export function linkTransactions() {
	return client.post('/transactions/link');
}

export function getPaymentCardType(id) {
	return client.get(`/cardTypes/${id}`).then(getData);
}

export function updatePaymentCardType(id, cardType) {
	return client.put(`/cardTypes/${id}`, cardType);
}

export function createPaymentCardType(cardType) {
	return client.post('/cardTypes', cardType).then(getData);
}

export function getPaymentCardTypes(offset, limit) {
	return client.get('/cardTypes', { params: { offset, limit } }).then(getData);
}

export function getAllPaymentCardTypes() {
	return client.get('/cardTypes/all').then(getData);
}

export function deletePaymentCardType(id) {
	return client.delete(`/cardTypes/${id}`);
}

export function getCommission(id) {
	return client.get(`/commissions/${id}`).then(getData);
}

export function updateCommission(id, commission) {
	return client.put(`/commissions/${id}`, commission);
}

export function createCommission(commission) {
	return client.post('/commissions', commission).then(getData);
}

export function getCommissions(offset, limit) {
	return client.get('/commissions', { params: { offset, limit } }).then(getData);
}

export async function findCommissions(search) {
	const { data } = await client.get('/commissions', { params: { search } });
	return data.commissions.map(c => ({ value: c.id, label: c.description }));
}

export function deleteCommission(id) {
	return client.delete(`/commissions/${id}`);
}

export function getCountry(id) {
	return client.get(`/countries/${encodeURIComponent(id)}`).then(getData);
}

export function updateCountry(id, country) {
	return client.put(`/countries/${encodeURIComponent(id)}`, country);
}

export function createCountry(country) {
	return client.post('/countries', country);
}

export function getCountries(offset, limit, params) {
	return client.get('/countries', { params: { ...params, offset, limit } }).then(getData);
}

export function deleteCountry(id) {
	return client.delete(`/countries/${encodeURIComponent(id)}`);
}
