import React, { Component, Suspense } from 'react';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner';
import Title from './Title';

const LAST_MODIFIED = new Date(document.lastModified);

export default class LazyLoader extends Component {
	static getDerivedStateFromError(error) {
		if(error.name === 'ChunkLoadError') {
			axios.get('/').then(res => {
				const current = new Date(res.headers['last-modified']);
				if(current > LAST_MODIFIED || isNaN(current)) {
					window.location.reload();
				}
			}).catch(err => {
				console.warn('Failed to load html', err);
			});
		}
		return { error: error.message };
	}

	render() {
		if(this.state?.error) {
			return <Container>
				<Title>An error occurred while loading the page</Title>
				{this.state.error}
			</Container>;
		}
		return <Suspense fallback={<LoadingSpinner />}>
			{this.props.children}
		</Suspense>;
	}
}
