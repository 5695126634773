import React, { useEffect, useState } from 'react';
import Login from './auth/Login';
import Routes from './Routes';
import Navigation from './Navigation';
import LazyLoader from '../components/LazyLoader';
import { checkLogin, getVersion } from '../api';
import LoadingSpinner from '../components/LoadingSpinner';
import pkg from '../../package.json';
import { useBackground } from '../hooks/background';

export default function App() {
	const [user, setUser] = useState(null);
	const [serverInfo, setServerInfo] = useState(null);
	const { setBody, setNav } = useBackground();
	const login = u => {
		setUser(u);
		setBody(u.bodyColor);
		setNav(u.navColor);
	};
	useEffect(() => {
		checkLogin().then(login).catch(() => setUser(false));
		getVersion().then(i => {
			setServerInfo(i);
			document.body.className = i.country;
		}).catch(err => {
			console.warn('Failed to get backend version', err);
		});
	}, []);
	if(user === null) {
		return <LoadingSpinner />;
	} else if(!user) {
		return <Login setUser={login} frontendVersion={pkg.version} backendVersion={serverInfo?.version} country={serverInfo?.country} />;
	}
	return <LazyLoader>
		<Navigation user={user} country={serverInfo?.country} />
		<main>
			<Routes user={user} country={serverInfo?.country} />
		</main>
	</LazyLoader>;
}
