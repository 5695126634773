import HttpStatus from 'http-status-codes';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getTask, getRecentTasks, updateBulkTask } from '../api';
import { wait } from '../utils';

const TASKLIST_INTERVAL = 1500;
const INTERVAL = 500;
const STATE = { task: null, fetching: false };

function setFetching(setState, fetching) {
	setState(prev => {
		if(prev.fetching === fetching) {
			return prev;
		}
		return { ...prev, fetching };
	});
}

async function waitForTask(id, cancel, setState, setError) {
	setFetching(setState, true);
	do {
		try {
			const response = await getTask(id);
			if(cancel.stop) {
				return;
			}
			const fetching = response.status === HttpStatus.ACCEPTED;
			setState({ task: response.data, fetching });
			if(fetching) {
				await wait(cancel, INTERVAL);
			} else {
				break;
			}
		} catch(err) {
			if(err.data) {
				setState({ task: err.data, fetching: false });
			} else {
				setFetching(setState, false);
			}
			setError(err);
			break;
		}
	} while(!cancel.stop);
}

export function useTask(id, setError) {
	const [state, setState] = useState(STATE);
	useEffect(() => {
		if(id) {
			const cancel = { stop: false, timeout: 0 };
			waitForTask(id, cancel, setState, setError);
			return () => {
				clearTimeout(cancel.timeout);
				cancel.stop = true;
				setFetching(setState, false);
			};
		}
		setState(STATE);
	}, [id, setError]);
	return state;
}

async function fetchTasks(setTasks, cancel, setError, params) {
	do {
		try {
			const tasks = await getRecentTasks(params);
			if(!cancel.stop) {
				tasks.forEach(task => {
					['started', 'ended'].forEach(key => {
						const date = new Date(task[key]);
						if(!task[key] || isNaN(date)) {
							task[key] = null;
						} else {
							task[key] = date;
						}
					});
				});
				setTasks(oldTasks => {
					if(oldTasks?.every((o, i) => o.id === tasks[i].id && o.state === tasks[i].state)) {
						return oldTasks;
					}
					return tasks;
				});
				await wait(cancel, TASKLIST_INTERVAL);
			}
		} catch(err) {
			if(!cancel.stop) {
				setError(err);
				await wait(cancel, TASKLIST_INTERVAL);
			}
		}
	} while(!cancel.stop);
}

export function useTaskList(limit) {
	const [taskError, setError] = useState(null);
	const [tasks, setTasks] = useState(null);
	useEffect(() => {
		const cancel = { timeout: 0, stop: false };
		fetchTasks(setTasks, cancel, setError, { limit });
		return () => {
			clearTimeout(cancel.timeout);
			cancel.stop = true;
		};
	}, []);
	return {tasks, taskError};
}

export function useBulkTask(type, createTask, setUpdating, setError, setRefresh, onStartError) {
	const [state, setState] = useState(null);
	const { task, fetching } = useTask(state?.task, setError);
	const wasFetching = useRef(fetching);
	useEffect(() => {
		if(!fetching && wasFetching.current) {
			setRefresh?.(prev => !prev);
		}
		wasFetching.current = fetching;
	}, [fetching, setRefresh]);
	const { start, update, closeModal } = useMemo(() => {
		return {
			async start(...params) {
				setUpdating(true);
				setError(null);
				try {
					const res = await createTask(...params);
					setState(res);
				} catch(err) {
					setError(err);
					onStartError?.(err, setState);
				} finally {
					setUpdating(false);
				}
			},
			async update(updateState) {
				setUpdating(true);
				setError(null);
				try {
					await updateBulkTask(state.task, updateState, type);
					if(updateState === 'cancel') {
						setState(null);
					} else {
						setState(prev => ({ ...prev, started: true }));
					}
				} catch(err) {
					setError(err);
				} finally {
					setUpdating(false);
				}
			},
			closeModal: () => setState(null)
		};
	}, [state?.task, type, createTask, setUpdating, setError, onStartError]);
	return { start, update, state, closeModal, task, fetching };
}
