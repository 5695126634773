import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation } from 'react-router-dom';
import { logout } from '../api';
import { MenuOptions } from '../components/home/Menu';
import Breadcrumbs from '../components/navigation/Breadcrumbs';
import { useBackground } from '../hooks/background';

export default function Navigation({ user, country }) {
	const [open, setOpen] = useState(false);
	const location = useLocation();
	const { nav: backgroundColor } = useBackground();
	useEffect(() => {
		setOpen(false);
	}, [location.pathname]);
	return <Navbar className="fw-bold" expand="sm" sticky="top" expanded={open} style={{ backgroundColor }} onToggle={() => setOpen(p => !p)}>
		<Container>
			<Navbar.Brand to="/" as={Link}>
				<img alt="" src="/plutus.png" /> Plutus {country && <img alt={country} src={`/flag_${country.toLowerCase()}.svg`} className="flag" />}
			</Navbar.Brand>
			<Breadcrumbs />
			<Navbar.Toggle />
			<Navbar.Collapse className="flex-grow-0">
				<Nav>
					<Nav.Item className="d-block d-sm-none">
						<MenuOptions user={user} country={country} />
					</Nav.Item>
					<Nav.Link as={Link} to="/account" title="My account">{user.firstName} {user.lastName}</Nav.Link>
					<Nav.Link as={Link} to="/" onClick={e => {
						e.preventDefault();
						logout().then(() => {
							window.location = '/';
						}).catch(err => {
							window.alert(err.message);
						});
					}}>Logout</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Container>
	</Navbar>;
}
